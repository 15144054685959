import React, { useState, useRef, useEffect } from 'react';
import { useTransition, animated, config } from 'react-spring';
import PropTypes from 'prop-types';
import {
  FeatureRecipePromoComponent,
  FeatureRecipePromoInner,
  NewRecipeButtonWrap,
} from './FeatureRecipePromo.styles.js';

import PromoBox from 'components/shared/PromoBox';
import InterruptorRibbon from 'components/shared/InterruptorRibbon';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import translate from 'helpers/translate';
import {OverTheTopIframeWrapper, OverTheTopWrapper} from "../Text/Text.styles";
import {RichText} from "../shared/JssOverrides";

const FeatureRecipePromo = ({ fields = {}, params = {}, sitecoreContext = {} }) => {
  const { viewBag = {} } = sitecoreContext;
  const { recipePages = [] } = fields;
  const [activeIndex, setActiveIndex] = useState(0);
  const tapButtonImage = fields?.tapButtonImage?.value;
  const {
    backgroundColor = '',
    removeTopBackground = 'false',
  } = params;
  const slides =
    Array.isArray(recipePages) && recipePages?.length > 0
      ? recipePages?.map((item, index) => ({ ...item, slideIndex: index }))
      : [];

  const transitions = useTransition([activeIndex], (item) => item, {
    from: { opacity: 0, transform: 'translateY(0px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-40px)' },
    config: config.gentle,
  });

  const handleRecipeChange = () => {
    if (activeIndex === recipePages?.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };
  const title = slides[activeIndex]?.title;
  const description = slides[activeIndex]?.description;
  const url = slides[activeIndex]?.url;
  const getVariantColours = () => {
    // Your logic to determine the variant colors
    return 'red blue'; // Example: returning 'red blue' as the variant colors
  };
  const featureRecipePromoClassName = `feature-recipe-promo ${getVariantColours()}`;
  return (
    <FeatureRecipePromoComponent className={featureRecipePromoClassName}>
      {removeTopBackground !== 'true' && <span className="top-background"/>}
      <FeatureRecipePromoInner>
        <div>
          {recipePages?.length > 1 && (
            <NewRecipeButtonWrap>
              <button onClick={handleRecipeChange}>
                {tapButtonImage && <img src={tapButtonImage.src} alt="circle graphic button" />}
                {tapButtonImage?.alt && <span>{tapButtonImage?.alt}</span>}
              </button>
            </NewRecipeButtonWrap>
          )}
          {transitions.map(({ item, key, props }, index) => {
            const image = slides[item]?.image;
            const imageUrl = image?.url ? `${image.url}?mw=800` : '';
            return (
                <div key={`${key}-${index}`} className="recipe-image">
                  <animated.div key={key} style={{ ...props }}>
                    <img src={imageUrl} alt={image?.alt} />
                  </animated.div>
                </div>
            );
          })}
        </div>
        <PromoBox
          className="promo-box"
          title={{ value: title }}
          text={{ value: description }}
          cta={{
            value: {
              text: translate(viewBag, 'BushsBeans.Recipes.FeaturedRecipePromo.GetRecipeLinkText'),
              href: url,
            },
          }}
        >
          {params?.showRibbon === '1' && (
            <>
              <InterruptorRibbon className="ribbon-left" variant="red" />
              <InterruptorRibbon className="ribbon-right" variant="red" />
            </>
          )}
        </PromoBox>
      </FeatureRecipePromoInner>
    </FeatureRecipePromoComponent>
  );
};

FeatureRecipePromo.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(FeatureRecipePromo);