import styled from 'styled-components';

const getbackgroundColor = ({backgroundColor, theme} = {}) => {
    const backgroundColors = {
        tan: {
            background: theme.colours.tan,
        },
        yellow: {
            background: theme.colours.yellow,
        },
    };

    if (backgroundColor in backgroundColors) return backgroundColors[backgroundColor];

    return backgroundColors.yellow;
};

export const HeroWithTextLeftComponent = styled.section`
    position: relative;
    padding-bottom: ${({theme}) => theme.spacing(80)};
    background-color: ${({theme, backgroundColor}) =>
            backgroundColor ? getbackgroundColor({theme, backgroundColor}).background : theme.colours.yellow};
    padding: 0 35px;
    .container{
        padding: 100px 0;
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		gap: 50px;
		width: 1550px;
		max-width: 100%;
		margin: auto;
        @media (max-width: 900px){
            gap: 0px;
        }
		@media (max-width: 650px){
			padding: 50px 0;
		}
    }
`;

export const CopyDiv = styled.div`
    position: relative;
	display: flex;
	flex-flow: column;
	grid-column: span 6 / span 6;
	align-self: center;
    .inner{
        width: 530px;
        max-width: 100%;
        margin: 0 auto 0 0;
    }
    img{
        width: 100%;
        height: auto;
        margin: auto;
    }
    h2{}
    p{
        text-align: center;
        display: block;
		color:  ${({ theme }) => theme.colours.brown};
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 0;
        span{
			color:  ${({ theme }) => theme.colours.red};
        }
    }
    @media (max-width: 900px){
		grid-column: span 12 / span 12;
        .inner{
            margin: auto;
        }
    }
	@media (max-width: 600px){
        width: 100%;
		p{
            font-size: 18px;
        }
	}
`;

export const MediaDiv = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	grid-column: span 6 / span 6;
	align-self: center;
	@media (max-width: 900px){
		grid-column: span 12 / span 12;
        padding: 0 10%;
	}
	@media (max-width: 600px){
		padding: 0 5%;
		top: 15px;
	}
`;