import styled from 'styled-components';

const getbackgroundColor = ({ backgroundColor, theme } = {}) => {
  const backgroundColors = {
    tan: {
      background: theme.colours.tan,
    },
    yellow: {
      background: theme.colours.yellow,
    },
  };

  if (backgroundColor in backgroundColors) return backgroundColors[backgroundColor];

  return backgroundColors.yellow;
};

export const RightImage = styled.div``;

export const LeftImage = styled.div``;

export const OffsetFeaturePromoComponent = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colours.checkColourExists(backgroundColor) ||
    getbackgroundColor({ theme, backgroundColor }).background};
`;

export const InnerWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing(60)} ${theme.spacing(20)} ${theme.spacing(75)}`};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => `${theme.spacing(60)} ${theme.spacing(50)} ${theme.spacing(75)}`};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => `${theme.spacing(80)} ${theme.spacing(50)}`};
  }
`;

export const IntroBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    display: none;
  }

  .button-default {
    margin: ${({ theme }) => `0 auto ${theme.spacing(50)}`};
  }

  h2 {
    padding-top: ${({ theme }) => `${theme.spacing(30)}`};
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => theme.spacing(32)};
    line-height: ${({ theme }) => theme.spacing(40)};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      font-size: ${({ theme }) => `clamp(${theme.spacing(32)}, 6vw, ${theme.spacing(72)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(40)}, 6vw, ${theme.spacing(76)})`};
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => `0 ${theme.spacing(30)} ${theme.spacing(30)}`};
      padding-top: ${({ theme }) => `${theme.spacing(60)}`};
    }
  }

  p {
    color: ${({ theme }) => theme.colours.adaBrown};
    text-align: center;
    .mb{
      margin-bottom: ${({ theme }) => theme.spacing(20)};
      font-weight: 400;
    }
    span{
      display: block;
      font-weight: 600;
    }
  }

  hr {
    width: ${({ theme }) => theme.spacing(35)};
    margin: 0 auto;
    border: ${({ theme }) => `2px solid ${theme.colours.red}`};
    transform: rotate(90deg);

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: ${({ theme }) => theme.spacing(60)};
    }
  }

  .title-image {
    width: 50px;
    max-width: unset;
    height: 100%;
    margin: ${({ theme }) => `0 auto ${theme.spacing(30)}`};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: 80px;
      margin: ${({ theme }) => `0 auto ${theme.spacing(60)}`};
    }
  }
  .post-text-content{
    display: flex;
    justify-content: center;
    gap: 25px;
    ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
       flex-flow: column;
       gap: 10px;
    }
  }
`;

export const ImageWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(100)};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-bottom: ${({ theme }) => theme.spacing(150)};
  }

  .sc-image-wrapper {
    text-align: center;
  }

  ${LeftImage} {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;

    img {
      width: 100%;
      max-width: 42vw;
      height: auto;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-width: ${({ theme }) => theme.spacing(543)};
      }
    }
  }

  ${RightImage} {
    z-index: 99;
    display: flex;
    flex: 1;
    align-items: flex-start;
    align-self: flex-end;
    justify-content: flex-end;
    transform: translateY(100px);

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      transform: translateY(120px);
    }

    img {
      width: 100%;
      max-width: 42vw;
      height: auto;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-width: ${({ theme }) => theme.spacing(543)};
      }
    }
  }

  ${IntroBlock} {
    display: none;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      display: flex;
      flex: 1.5;
      flex-direction: column;
      margin-bottom: ${({ theme }) => theme.spacing(50)};
      padding: ${({ theme }) => `0 ${theme.spacing(20)}`};
    }

    ${({ theme }) => theme.breakPoints.minBp('1500px')} {
      flex: 2;
      padding: 0;
    }
  }
`;

export const ScrollTextContainer = styled.div`
  position: absolute;
  bottom: -50px;
  margin-bottom: ${({ theme }) => theme.spacing(50)};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    bottom: -20px;
  }
`;