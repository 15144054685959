import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    yellow: {
      background: theme.colours.yellow,
      title: theme.colours.red,
      text: theme.colours.adaBrown,
    },
    blue: {
      background: theme.colours.blue,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    brown: {
      background: theme.colours.brown,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    transparent: {
      background: 'transparent',
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const FeatureRecipePromoInner = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: 90%;
  }

  ${({ theme }) => theme.breakPoints.minBp('1920px')} {
    max-width: 1700px;
  }
`;

export const FeatureRecipePromoComponent = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(130)};
  background-color: ${({ theme }) => theme.colours.tan};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: ${({ theme }) => theme.spacing(160)};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding-top: ${({ theme }) => theme.spacing(240)};
    padding-bottom: ${({ theme }) => theme.spacing(80)};
  }

  .top-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: ${({ theme }) => theme.colours.yellow};
  }

  & .recipe-image {
    position: absolute;
    z-index: 1;
    width: 100%;
    text-align: center;
    top: ${({ theme }) => theme.spacing(-120)};

    ${({ theme }) => theme.breakPoints.minBp('767px')} {
      top: -25%;
    }

    & img {
      max-width: ${({ theme }) => theme.spacing(286)};
      height: auto;
      margin: 0 auto;
      ${({ theme }) => theme.breakPoints.minBp('767px')} {
        max-width: 33.37222870478413%;
      }
      border-radius: 50%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  & .promo-box {
    width: auto;
    padding-top: ${({ theme }) => theme.spacing(160)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      padding-top: 22%;
      padding-bottom: 100px;
    }

    & button {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }

    & .ribbon-left {
      position: absolute;
      right: 0;
      bottom: ${({ theme }) => theme.spacing(14)};
      left: 0;
    }
    & .ribbon-right {
      display: none;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      position: relative;
      overflow: hidden;

      & button {
        margin-bottom: 0;
      }

      & .ribbon-left {
        position: absolute;
        bottom: 0;
        left: ${({ theme }) => theme.spacing(90)};
        transform: translateY(100%) rotate(-90deg);
        transform-origin: left bottom;
      }

      & .ribbon-right {
        position: absolute;
        right: ${({ theme }) => theme.spacing(90)};
        bottom: 0;
        display: block;
        transform: translateY(100%) rotate(90deg);
        transform-origin: right bottom;
      }
    }

    > h1 {
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: 800px;
        margin: 0 auto 36px;
      }
    }

    > .h1 {
      text-transform: uppercase;
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: 800px;
        margin: 0 auto 36px;
      }
    }

    > p {
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: 800px;
        margin: 0 auto 48px;
      }
    }
  }
`;

export const NewRecipeButtonWrap = styled.div`
  position: absolute;
  left: 60%;
  z-index: 2;
  width: ${({ theme }) => `clamp(${theme.spacing(109)}, 12vw, ${theme.spacing(209)})`};
  height: ${({ theme }) => `clamp(${theme.spacing(109)}, 12vw, ${theme.spacing(209)})`};
  top: 5%;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    top: 15%;
  }

  > button {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colours.red};
    font-weight: bold;
    font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(20)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(20)}, 4vw, ${theme.spacing(37)})`};
    text-align: center;
    text-transform: uppercase;
    transform: rotate(5deg);
    cursor: pointer;

    & img {
      width: 100%;
      height: auto;
    }

    & span {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    &:focus {
      outline: 0;
    }
  }
`;